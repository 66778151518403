import React from 'react'
import { Helmet } from "react-helmet"
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Logo from '../img/Logo'
import BackgroundImage from 'gatsby-background-image'

const loadingAnimation = function(){
  setTimeout(function(){
    document.body.classList.add("loaded");
  }, 1000);
}

export const IndexPageTemplate = ({
  heroImage,
  ourMenus,
  about,
  contact
}) => (
  <>
    <section className="hero pageSection">
      <div className="backgroundContainer">
        <BackgroundImage Tag="span"
            fluid={heroImage.childImageSharp.fluid}
            loading="eager"
            onLoad={loadingAnimation()}
        ></BackgroundImage>
      </div>

      
       <Logo />
    </section>

    <div className="pageContent pageContent__animate">
      <div className="pageContent__inner"> 

        <section className="theme__box theme__box--dark">
          <div className="backgroundContainer">
            <BackgroundImage Tag="span"
                fluid={ourMenus.image.childImageSharp.fluid}
            ></BackgroundImage>
          </div>

          <div className="container">
            <h2>{ourMenus.heading}</h2>
            <p>{ourMenus.shortDescription}</p>
            <Link className="btn btn--ghost" to="/menus">
              See all menus
            </Link>
          </div>
        </section>

        <section className="theme__box">
          <div className="backgroundContainer">
            <BackgroundImage Tag="span"
                fluid={about.image.childImageSharp.fluid}
            ></BackgroundImage>
          </div>
          <div className="container">
            <h2>{about.heading}</h2>
            <p>{about.shortDescription}</p>
            <Link className="btn" to="/about">
              Read more
            </Link>
          </div>
        </section>

          <section className="theme__box theme__box--small theme__box--dark">
          <div className="backgroundContainer">
            <BackgroundImage Tag="span"
                fluid={contact.image.childImageSharp.fluid}
            ></BackgroundImage>
          </div>
          <div className="container">
            <h2>{contact.heading}</h2>
            <p>{contact.shortDescription}</p>
            <Link className="btn btn--ghost" to="/contact">
              Contact Us
            </Link>
          </div>
        </section>
      </div>
    </div>
  </>
)

IndexPageTemplate.propTypes = {
  heroImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  ourMenus: PropTypes.object,
  about: PropTypes.object
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{frontmatter.meta.metaTitle}</title>
        <meta name="description" content={frontmatter.meta.metaDescription} />
      </Helmet>
      <Layout>
        <IndexPageTemplate
          heroImage={frontmatter.heroImage}
          ourMenus={frontmatter.ourMenus}
          about={frontmatter.about}
          contact={frontmatter.contact}
        />
      </Layout>
    </>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1080, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        ourMenus{
          heading
          shortDescription
          image {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        about {
          heading
          shortDescription
          image {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        contact {
          heading
          shortDescription
          image {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 80) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        meta{
          metaTitle
          metaDescription
        }
      }
    }
  }
`
